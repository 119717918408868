"use client";

import { useSession } from "next-auth/react";
import { useSearchParams } from "next/navigation";
import { updateAdAttribution } from "./ad-attribution-action";

export const attributionKey = "mh-attr";

const facebookParam = "fbclid";
const googleParam = "gclid";
const redditParam = "rclid";
const ref = "ref";
const utmSource = "utm_source";
const via = "via";
const internal = "int";

export function getAdAttributionFromLocalStorage() {
  if (typeof window === "undefined") {
    return null;
  }

  return localStorage.getItem(attributionKey);
}

export function AdAttribution() {
  const searchParams = useSearchParams();
  const { data: session } = useSession();

  if (!searchParams) {
    return null;
  }

  if (typeof window === "undefined") {
    return null;
  }

  if (session && session.user.attributed === true) {
    return null;
  }

  const attribution = searchParams.get(attributionKey) || getAdAttributionFromLocalStorage();
  const facebookId = searchParams.get(facebookParam);
  const googleId = searchParams.get(googleParam);
  const redditIt = searchParams.get(redditParam);
  const referrer = searchParams.get(ref);
  const source = searchParams.get(utmSource);
  const viaParam = searchParams.get(via);
  const internalParam = searchParams.get(internal);

  let value: string | null = null;

  switch (true) {
    case !!attribution:
      value = attribution;
      break;
    case !!facebookId:
      value = `facebook:${facebookId.toString()}`;
      break;
    case !!googleId:
      value = `google:${googleId.toString()}`;
      break;
    case !!redditIt:
      value = `reddit:${redditIt.toString()}`;
      break;
    case !!referrer:
      value = `general:${referrer.toString()}`;
      break;
    case !!source:
      const utmId = searchParams.get("utm_id") || "no_utm_id";
      const utmMedium = searchParams.get("utm_medium") || "no_utm_medium";
      value = `${source}:${utmId}_${utmMedium}`;
      break;
    case !!viaParam:
      value = `general:${viaParam.toString()}`;
      break;
    case !!internalParam:
      value = `internal:${internalParam.toString()}`;
      break;
  }

  const handleAttribution = (value: string) => {
    updateAdAttribution(value)
      .then((res) => {
        if (res.type === "success") {
          localStorage.removeItem(attributionKey);
        }
      })
      .catch(() => {});
  };

  if (session) {
    if (value) {
      handleAttribution(value);
    }
  } else if (value) {
    localStorage.setItem(attributionKey, value);
  }

  return null;
}
